.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-clickableElement:hover {
  cursor: pointer;
}

.App-position-r {
  position: relative;
}

.App-spinner,
.App-central-snackbar {
  position: absolute;
  top: 50%;
  left: 50%;
}

.w-100 {
  width: 100% !important;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
  color: white;
}

.fc .fc-daygrid-day-number:hover {
  color: white;
  cursor: pointer;
}

.fc .fc-col-header-cell-cushion {
  padding-bottom: 10px;
  padding-top: 10px;
  color: white;
}

.css-miq28 {
  background-color: #171c24;
  min-height: 100%;
  padding-top: 32px;
  padding-bottom: 64px;
}

.css-1ndpc0p-MuiButtonBase-root-MuiButton-root:hover {
  color: white;
}

.css-1th56xf-MuiButtonBase-root-MuiIconButton-root:hover {
  color: white;
}

.css-bga1wh-MuiButtonBase-root-MuiButton-root:hover {
  color: white !important;
}

.css-hhvt0d-MuiButtonBase-root-MuiButton-root:hover {
  color: white !important;
}

.css-gf0hxf-MuiButtonBase-root-MuiIconButton-root {
  color: white !important;
}

.orange-multi-select-active:focus {
  outline: 0px !important;
}

.leaflet-container {
  height: 500px;
  width: 100%;
}
